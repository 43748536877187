import { apiSignUrl } from '@/api/api.js'
import { sha256 } from 'js-sha256'

const langs = {
  disproveSize: '文件大小不能超过',
  disproveFormat: '您上传的文件格式不符合要求',
  uploadFailed: '上传失败，请稍后再试',
}
const fileKey = (options) => {
  const { dir, ext } = options
  const str = `${Date.now()}${Math.ceil(Math.random() * 1000)}`

  return `${dir}${sha256(str)}${ext}`
}
const getSizeByByte = (bytes, fixed = 0) => {
  if (Number(bytes) === 0) {
    return '0 B'
  }

  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const size = `${(bytes / Math.pow(k, i)).toFixed(fixed)} ${sizes[i]}`
  return size
}

export const UPLOAD_DEFAULT_OPTIONS = {
  file: null,
  url: '',
  exParams: {}, // 随文件上传一起提交的扩展参数
  limitFormat: [], // 格式限制
  limitSize: 0, // 大小限制，单位为k，0为不限制
  check: true,
  progressSplit: null,
  onMD5Progress() {}, // 计算文件MD5进度
  onProgress() {},
  onSuccess() {},
  onError() {},
}

const NAME_FILTER_REG = /,|，|"|'|“|‘|;|；/g

const updateNewFile = (opt) => {
  const xhr = new XMLHttpRequest()

  const uploadFile = function (opt) {
    const form = new FormData()
    let { file, onError = () => {} } = opt

    for (const i in opt.exParams) {
      form.append(i, opt.exParams[i])
    }

    // 上传的文件名过滤特殊字符，避免上传后访问不到的问题
    const fileName = (file.name || 'image.png').replace(NAME_FILTER_REG, '')
    form.append('file', file, fileName)

    if (xhr.upload) {
      // 上传中
      xhr.upload.addEventListener(
        'progress',
        e => {
          const progress = parseInt((e.loaded / e.total) * 100)
          opt.onProgress && opt.onProgress(progress)
        },
        false
      )

      xhr.onreadystatechange = function () {
        if (xhr.readyState !== 4) {
          return false
        }

        if (xhr.status === 0) {
          onError({ status: 'exception', payload: { msg: langs.uploadFailed } })
          return false
        }

        // 当错误为OSS的XML格式的报错时，将错误抛出
        // 详见：https://help.aliyun.com/knowledge_detail/32005.html
        const contentType = xhr.getResponseHeader('Content-Type')
        if (contentType === 'application/xml') {
          onError({
            status: 'exception',
            payload: { msg: langs.uploadFailed },
          })
          return
        }

        if (xhr.responseText !== '') {
          let result
          try {
            result = JSON.parse(xhr.responseText)
          } catch (exc) {
            onError({
              status: 'exception',
              payload: { msg: langs.uploadFailed },
            })
            return
          }
          if (xhr.status === 200) {
            if (result.code === 200) {
              opt.onSuccess && opt.onSuccess(result)
            } else {
              onError({
                status: 'exception',
                payload: { msg: result.msg || langs.uploadFailed },
              })
            }
          } else {
            onError({
              status: 'exception',
              payload: { msg: langs.uploadFailed },
            })
          }
        }
      }

      // 开始上传
      xhr.open('POST', opt.url, true)

      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
      xhr.setRequestHeader('Cache-Control', 'private, max-age=2592000')
      xhr.setRequestHeader(
        'Content-Disposition',
        `attachment;filename=${encodeURI(fileName)}`
      )

      xhr.send(form)
    }
  }

  if (opt.self) {
    uploadFile(opt)
  } else {
    const _name = (opt.file.name || opt.fileName).replace(NAME_FILTER_REG, '')
    let ext = _name.match(/(\.)([A-Za-z0-9_]*)$/)
      ? _name.match(/(\.)([A-Za-z0-9_]*)$/)[0]
      : ''
    const options = { ...opt, ext }

    opt.exParams = {
      key: fileKey(options),
      policy: opt['policy'],
      OSSAccessKeyId: opt['accessid'],
      callback: opt['callback'],
      signature: opt['signature'],
      success_action_status: '200',
      'x:filename': _name,
      'Content-Disposition': `attachment;filename=${_name}`,
    }

    opt.url = opt.host

    uploadFile(opt)
  }
}

/**
 * 检测文件是否在服务器
 * @param options
 */
export function checkUpdateFile(opt = { ...UPLOAD_DEFAULT_OPTIONS }) {
  if (!opt.file) {
    return false
  }
  // 格式校验
  const name = opt.file.name || opt.fileName
  const type = opt.file.type
  const onError = opt.onError || (() => {})

  if (opt.limitFormat.length > 0) {
    const regexp = new RegExp(`.(?:${opt.limitFormat.join('|')})$`, 'gi')

    if (!regexp.test(type) && !regexp.test(name)) {
      onError({ status: 'exception', payload: { msg: langs.disproveFormat } })
      return false
    }
  }

  // 大小校验
  const size = opt.file.size ? opt.file.size / 1024 : null

  if (size && opt.limitSize > 0 && opt.limitSize < size) {
    onError({
      status: 'exception',
      payload: {
        msg: `${langs.disproveSize} ${getSizeByByte(opt.limitSize * 1024)}`,
      },
    })
    return false
  }

  apiSignUrl()
    .then(response => {
      updateNewFile({ ...opt, ...response.data })
    })
    .catch(err => {
      onError(err)
    })
}

/**
 * 文件上传
 */
export function file(options) {
  const opt = {
    ...UPLOAD_DEFAULT_OPTIONS,
    ...options,
  }
  return checkUpdateFile(opt)
}

export function img(options) {
  const opt = {
    ...UPLOAD_DEFAULT_OPTIONS,
    limitFormat: ['jpg', 'png', 'gif', 'jpeg'],
    maxWidth: 0, // 最大宽度限制，超过该宽度则进行压缩
    area: {
      // 裁剪范围
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    ...options,
  }

  if (opt.maxWidth > 0) {
    opt.exParams.max_width = opt.maxWidth
  }

  if (opt.area.x >= 0 && opt.area.y >= 0 && opt.area.w > 0 && opt.area.h > 0) {
    opt.exParams.xywh = `
      ${opt.area.x},
      ${opt.area.y},
      ${opt.area.w},
      ${opt.area.h}`
  }

  return checkUpdateFile(opt)
}
